import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IItem } from '../models/item.model';
import { IItemFilter } from '../models/item.filter.ts';
import { IPaginatedList } from 'src/app/models/paginated-list.model';
import { ItemType } from '../models/item-type.enum';
import { map } from 'rxjs/operators';

const API_ITEMS_URL = `${environment.urls.api}/items`;

@Injectable({ providedIn: 'root' })
export class ItemService {

  constructor(
    private readonly _http: HttpClient
  ) { }

  private _map(item: IItem): IItem {
    if (item.type === ItemType.Package) { item.category = 'Packages'; }
    return item;
  }

  find(id: number): Observable<IItem> {
    return this._http.get<IItem>(`${API_ITEMS_URL}/${id}`)
      .pipe(map(this._map));
  }

  search(partialFilter: Partial<IItemFilter>): Observable<IPaginatedList<IItem>> {
    const filter = { ...partialFilter } as any;

    if (partialFilter.excludeItemTypes !== undefined && partialFilter.excludeItemTypes.length > 0) {
      filter.excludeItemTypes = partialFilter.excludeItemTypes?.join(',');
    }

    Object.keys(filter).forEach(key => filter[key] === undefined && delete filter[key]);

    return this._http.get<IPaginatedList<IItem>>(`${API_ITEMS_URL}/search`, { params: filter })
      .pipe(map((response: IPaginatedList<IItem>) => {
        response.items = response.items.map(this._map);
        return response;
      }));
  }

}
