import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IBookingServiceRequest } from "src/app/modules/bookings/bookings.module";
import { IStaff } from "../../models";
import { IItem } from 'src/app/modules/items/models/item.model';

@Component({
  selector: 'app-staff-service',
  templateUrl: './staff-service.component.html'
})
export class StaffServiceComponent {

  @Input() availableStaff!: IStaff[];
  @Input() booking!: IBookingServiceRequest;
  @Input() service!: IItem;

  @Output() changeBooking = new EventEmitter<IBookingServiceRequest>();

  onChangeBooking() {
    this.changeBooking.emit(this.booking);
  }

}
