import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { IBookingServiceRequest } from 'src/app/modules/bookings/bookings.module';
import { BusinessService } from 'src/app/modules/businesses/business.module';
import { IStaff, StaffService } from '../../staff.module';
import { ItemService } from 'src/app/modules/items/services/item.service';
import { IItem } from 'src/app/modules/items/models/item.model';
import { ItemType } from 'src/app/modules/items/models/item-type.enum';

@Component({
  templateUrl: './staff.component.html'
})
export class StaffComponent implements OnInit {

  constructor(
    private readonly _businessService: BusinessService,
    private readonly _logger: NGXLogger,
    private readonly _itemService: ItemService,
    private readonly _staffService: StaffService,
    public readonly appService: AppService
  ) { }

  private _rosteredStaff: IStaff[] = [];

  private readonly _isValid$ = new BehaviorSubject<boolean>(false);

  public availableStaff: { [itemRef: number]: number[] } = {};
  public bookedServices: IBookingServiceRequest[] = [];
  public editBooking: IBookingServiceRequest | undefined;
  public editService: IItem | undefined;
  public loading = true;
  public noStaff = false;
  public selectedServices: IItem[] = [];
  public view: 'services' | 'staff' = 'services';

  public readonly branch$ = this.appService.branch$;
  public readonly business$ = this._businessService.business$;
  public readonly isValid$ = this._isValid$.asObservable();
  public readonly itemType = ItemType;

  private _getBookedServices(): Observable<any> {
    return this.appService.bookingServiceRequests$
      .pipe(
        take(1),
        tap(bookedServices => this.bookedServices = bookedServices),
        map(bookedServices => bookedServices.map(s => s.itemRef)),
        mergeMap(serviceRefs => this._staffService.getStaffForServices(serviceRefs)),
        tap(availableServiceStaff => this.availableStaff = availableServiceStaff)
      );
  }

  private _getRosteredStaff(): Observable<any> {
    return this._staffService.rosteredStaff$
      .pipe(
        take(1),
        tap(staff => this._rosteredStaff = staff)
      );
  }

  private _getSelectedServices(): Observable<any> {
    return this.appService.selectedServicesAndPackages$
      .pipe(
        take(1),
        tap(services => this.selectedServices = services)
      );
  }

  private _selectDefaultStaff() {
    this.bookedServices.forEach(service => {
      const staff = this.getAvailableStaffForService(service.itemRef);

      if (staff.length === 0) {
        this._logger.debug('[StaffComponent].[selectDefaultStaff] no staff for service', service);
        this.noStaff = true;
      }

      if (staff.length === 1) {
        service.staffRef = staff[0].id;
        service.staffPreferred = true;
      }
    });
  }

  private _validate() {
    let isValid = true;

    if (this.noStaff) {
      isValid = false;
    }

    if (!this.bookedServices.every(s => (s.staffRef != undefined && s.staffRef > 0) || !s.staffPreferred)) {
      isValid = false;
    }

    this._logger.trace('[StaffComponent].[validate]', isValid);

    this._isValid$.next(isValid);

    if (isValid) {
      this.appService.setStaff();
    }
  }

  ngOnInit(): void {
    this.appService.setStep('staff');

    forkJoin([
      this._getBookedServices(),
      this._getRosteredStaff(),
      this._getSelectedServices()
    ]).subscribe(() => {
      this._selectDefaultStaff();
      this._validate();

      this.loading = false;
    });
  }

  getAvailableStaffForService(itemRef: number): IStaff[] {
    if (!this.availableStaff[itemRef]) { return []; }

    const data = this.availableStaff[itemRef]
      .map(staffRef => this._rosteredStaff.find(s => s.id === staffRef))
      .filter(staff => !!staff)
      .map(staff => staff!);

    return data;
  }

  getBooking(itemRef: number, packageRef?: number): IBookingServiceRequest {
    return this.bookedServices.find(i => i.itemRef === itemRef && i.packageRef === packageRef)!;
  }

  getBookingsForPackage(service: IItem): IBookingServiceRequest[] {
    return service.components
      .map(c => this.getBooking(c.itemId, service.id));
  }

  getService(itemId: number): Observable<IItem> {
    return this._itemService.find(itemId);
  }

  getServiceStaffName(itemRef: number): string | undefined {
    const staffRef = this.bookedServices.find(s => s.itemRef === itemRef)?.staffRef;
    const staff = this._rosteredStaff.find(s => s.id === staffRef);
    return staff?.fullName;
  }

  onContinue(): void {
    this.appService.goToNextStep();
  }

  onSetBookingNoPreference() {
    this.editBooking!.staffRef = undefined;
    this.editBooking!.staffPreferred = false;
    this._validate();
    this.view = 'services';
  }

  onSetBookingStaff(staff: IStaff) {
    this.editBooking!.staffRef = staff.id;
    this.editBooking!.staffPreferred = true;
    this._validate();
    this.view = 'services';
  }

  onBookingChange(booking: IBookingServiceRequest) {
    this.editBooking = booking;
    this._itemService.find(booking.itemRef).subscribe(service => {
      this.editService = service;
      this.view = 'staff';
    });
  }

  onStaffBack() {
    this.view = 'services';
  }

}
