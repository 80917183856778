import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { IBookingServiceRequest } from 'src/app/modules/bookings/bookings.module';
import { IItem } from 'src/app/modules/items/models/item.model';

@Component({
  selector: 'app-staff-package',
  templateUrl: 'staff-package.component.html'
})
export class StaffPackageComponent implements OnInit {

  @Input() package?: IItem;
  @Input() bookings: IBookingServiceRequest[] = [];

  @Output() changeBooking = new EventEmitter<IBookingServiceRequest>();

  constructor(
    private _logger: NGXLogger
  ) { }

  ngOnInit() {
    this._logger.debug('[StaffPackageComponent].[ngOnInit] package: ', this.package);
    this._logger.debug('[StaffPackageComponent].[ngOnInit] bookings: ', this.bookings);
  }

  getBookingForService(serviceItemRef: number): IBookingServiceRequest | undefined {
    return this.bookings.find(b => b.itemRef === serviceItemRef);
  }

  onChangeBooking(booking: IBookingServiceRequest) {
    this.changeBooking.emit(booking);
  }

}
