<div class="card" *ngIf="!!package">
  <h5 class="card-header">{{package.name}}</h5>

  <div class="list-group list-group-flush">

    <button *ngFor="let service of package.components"
            class="list-group-item list-group-item-action p-4">
      <div *ngIf="getBookingForService(service.itemId); let booking">
        <app-service-staff [booking]="booking" (change)="onChangeBooking($event)" [serviceName]="service.itemName"></app-service-staff>
      </div>
    </button>

  </div>

</div>
